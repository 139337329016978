import React, { useEffect, useState } from 'react'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Box, InputGroup, MoreBar, Subheader } from '@revolut/ui-kit'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useParams } from 'react-router-dom'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { JobPostingInterface, LocationSource } from '@src/interfaces/jobPosting'
import LapeHTMLEditor from '@components/Inputs/LapeFields/LapeHTMLEditor'
import AutoStepper from '@components/Stepper/AutoStepper'
import SettingsButtons from '@src/features/SettingsButtons'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import ChangeJobPostingSidebar from '@src/pages/Forms/JobPosting/Components/ChangeJobPostingSidebar'
import Loader from '@components/CommonSC/Loader'
import { jobPostingsRequests } from '@src/api/jobPosting'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import { selectorKeys } from '@src/constants/api'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import { LocationInterface } from '@src/interfaces/requisitions'
import { getRequisitionRecruiter } from '@src/api/recruitmentGroups'
import { connect } from 'lape'
import { useGetPostingCompensationBands } from '@src/api/benchmarks'
import PostingCompensationBand from '@src/components/LocationCompensationBand/PostingCompensationBand'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { useGetJobPostingSettings } from '@src/api/settings'
import ManualCompensationBandForm from '@src/pages/Forms/JobPosting/Components/ManualCompensationBandForm'
import { useGetHiringEnabledLocations } from '@src/api/requisitions'
import { useSetJobDescriptionSections } from '@src/pages/Forms/JobPosting/utils'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { useGetSelectors } from '@src/api/selectors'

const General = () => {
  const form = useLapeContext<JobPostingInterface>()
  const { values, errors } = form
  const { id, specId } = useParams<{ id: string; specId: string }>()
  const [loading, setLoading] = useState(true)
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)

  const { data: jobPostingSettings } = useGetJobPostingSettings()
  useSetJobDescriptionSections()
  const { data: employmentTypeSelector } = useGetSelectors(
    selectorKeys.posting_employment_type,
  )
  const employmentTypeOptions = (employmentTypeSelector || []).map(employmentType => ({
    key: employmentType.id,
    label: employmentType.name,
    value: employmentType,
  }))

  const featureFlags = useSelector(selectFeatureFlags)
  const linkedinIntegration = featureFlags.includes(FeatureFlags.LinkedinIntegration)

  const enableAutomaticCompensationBands =
    jobPostingSettings?.enable_automatic_compensation_ranges

  const selectedLocationIds = (values?.locations ?? []).map(
    ({ id: locationId }) => locationId,
  )

  const mandatoryCompensationLocations = (
    jobPostingSettings?.mandatory_compensation_locations ?? []
  )
    .map(({ id: locationId }) => locationId)
    .filter(locationId => selectedLocationIds?.includes(locationId))

  const { data: compensationBands } = useGetPostingCompensationBands(
    values.id,
    values.locations
      ?.filter(
        item =>
          item.posting_compensation_enabled ||
          mandatoryCompensationLocations.includes(item.id),
      )
      ?.map(item => item.id),
  )

  const { data: enabledLocationsData } = useGetHiringEnabledLocations()
  const locationOptions = (enabledLocationsData ?? []).map(location => ({
    label: location.name,
    value: location,
  }))

  let specialisationId: string | undefined

  if (specId) {
    specialisationId = specId
  } else if (values?.specialisation?.id) {
    specialisationId = `${values.specialisation.id}`
  }

  const getDefaultPosting = async () => {
    try {
      if (
        specialisationId &&
        !id &&
        values?.sections?.every(
          section => section.content === '' || section.content === '<p><br></p>',
        )
      ) {
        const result = await jobPostingsRequests.getItems({
          filters: [
            {
              columnName: 'specialisation__id',
              filters: [{ id: specialisationId, name: `${specialisationId}` }],
            },
          ],
        })
        if (result.data) {
          const def = result.data.results?.find(d => d.is_generated)
          if (def) {
            const resultItem = await jobPostingsRequests.getItem(def.id)
            values.name = resultItem.data.name
            values.sections = resultItem.data.sections
          }
        }
      }
    } finally {
      setLoading(false)
    }
  }

  const updateDefaultRecruiter = () => {
    if (!values.recruiter && specialisationId) {
      getRequisitionRecruiter(specialisationId).then(recruiter => {
        if (recruiter) {
          values.recruiter = recruiter
        }
      })
    }
  }

  useEffect(() => {
    if (linkedinIntegration && !values.employment_type) {
      values.employment_type = {
        id: 'full_time',
        name: 'Full time',
      }
    }
  }, [linkedinIntegration])

  useEffect(() => {
    getDefaultPosting()
    updateDefaultRecruiter()
  }, [values?.specialisation?.id])

  useEffect(() => {
    if (specId !== undefined && !form.values.specialisation) {
      form.values.specialisation = { id: specId }
    }
  }, [specId])

  if (loading) {
    return <Loader />
  }

  // TODO: return when all salary bands will be uploaded to the system
  // const compensationUnavailable =
  //   isCompensationBandsLoading || !allCompensationBandsAvailable(compensationBands)

  return (
    <>
      <ChangeJobPostingSidebar
        onClose={() => setIsSidebarOpen(false)}
        specialisationId={specialisationId}
        isOpen={isSidebarOpen}
        isSelector={false}
      />
      <PageBody>
        <AutoStepper>
          <SettingsButtons>
            <MoreBar.Action onClick={() => setIsSidebarOpen(true)}>
              View other postings
            </MoreBar.Action>
          </SettingsButtons>

          <NewStepperTitle title="General details" />
          <InputGroup>
            {(values.id || !specId) && (
              <LapeRadioSelectInput
                name="specialisation"
                label="Specialisation"
                selector={selectorKeys.specialisations}
              />
            )}
            <LapeRadioSelectInput
              name="recruiter"
              label="Recruiter"
              selector={selectorKeys.employee}
            />
            <LapeNewInput name="name" label="Job posting title" required />
            {linkedinIntegration && (
              <LapeRadioSelectInput
                name="employment_type"
                label="Employment type"
                options={employmentTypeOptions}
                value={values.employment_type}
              />
            )}
            <Box>
              <Box my="-s-8">
                <Subheader variant="nested">
                  <Subheader.Title>Location setting</Subheader.Title>
                </Subheader>
              </Box>
              <LapeNewRadioButtons
                name="location_source"
                options={[
                  { label: 'From requisition', value: LocationSource.requisition },
                  { label: 'Select manually', value: LocationSource.manual },
                ]}
                defaultOptionIndex={0}
                variant="cell"
                direction="row"
              />
            </Box>
            {values.location_source === LocationSource.manual && (
              <>
                <LapeNewMultiSelect<LocationInterface>
                  name="locations"
                  placeholder="Locations"
                  required
                  options={locationOptions}
                  onAfterChange={locationValues => {
                    const entries = Object.entries(values.salary_bands_by_location ?? {})
                    const locationKeys = (locationValues ?? []).map(({ label }) => label)
                    const keepEntries = entries.filter(([key]) =>
                      locationKeys.includes(key),
                    )
                    if (keepEntries.length) {
                      values.salary_bands_by_location = Object.fromEntries(keepEntries)
                    }
                  }}
                />
                <PostingCompensationBand bands={compensationBands} />
              </>
            )}
            {!enableAutomaticCompensationBands &&
              !!mandatoryCompensationLocations.length && (
                <>
                  {values.location_source !== LocationSource.manual && (
                    <PostingCompensationBand bands={compensationBands} />
                  )}
                  <ManualCompensationBandForm
                    locationIds={mandatoryCompensationLocations}
                    values={values.salary_bands_by_location}
                    errors={errors.salary_bands_by_location}
                    onChange={salaryBandsByLocation => {
                      values.salary_bands_by_location = values.salary_bands_by_location
                        ? {
                            ...values.salary_bands_by_location,
                            ...salaryBandsByLocation,
                          }
                        : salaryBandsByLocation
                    }}
                  />
                </>
              )}
          </InputGroup>
          {values.sections?.map((section, idx) => (
            <React.Fragment key={idx}>
              <NewStepperTitle title={section.title} />
              <InputGroup>
                <LapeHTMLEditor
                  name={`sections.${idx}.content`}
                  placeholder={`${section.title}${
                    section.title === 'Nice to have' ? ' (Optional)' : ''
                  }`}
                  height={350}
                  required
                  addMarginToParagraphs
                />
              </InputGroup>
            </React.Fragment>
          ))}
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          previewUrl={result =>
            pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
              id: result.id,
              specId: specialisationId,
            })
          }
          useValidator
        />
      </PageActions>
    </>
  )
}

export default connect(() => {
  const params = useParams<{ id?: string; specId?: string }>()

  return (
    <PageWrapper>
      <PageHeader
        title={params.id ? 'Edit job posting' : 'New job posting'}
        backUrl={
          params.id
            ? pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, params)
            : ROUTES.RECRUITMENT.JOBS.JOB_POSTINGS
        }
      />
      <General />
    </PageWrapper>
  )
})
