import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { UploadSessionV2, UploadSessionV2Props } from './UploadSession'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { BulkDataImportUploadFileV2 } from '@src/features/BulkDataImportV2/BulkDataImportUploadFileV2'
import { ImportDataCategory } from '@src/interfaces/bulkDataImport'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { TableActionsProps } from '@src/features/GenericEditableTable/GenericEditableTable'

type Props<T> = {
  importRoute: string
  sessionRoute: string
  anyRoute: string
  apiEndpoint: string
  category: ImportDataCategory
  name: string
  tableActions?: (props: TableActionsProps) => React.ReactNode
  templateParams?: Record<string, string>
  header?: React.ReactNode
  hiddenColumns?: Record<string, boolean>
  onAfterConfirmRoute?: string
  ignoreQueryParams?: Array<'cols' | 'template' | 'updateEmployees'>
} & Pick<
  UploadSessionV2Props<T>,
  'row' | 'tableName' | 'entity' | 'getCustomEditCellAction'
>

export const BulkDataUploadV2 = <T,>({
  importRoute,
  sessionRoute,
  anyRoute,
  apiEndpoint,
  category,
  name,
  tableName,
  row,
  entity,
  tableActions,
  templateParams,
  header,
  hiddenColumns,
  onAfterConfirmRoute,
  ignoreQueryParams,
  getCustomEditCellAction,
}: Props<T>) => {
  return (
    <PageWrapper>
      {header}
      <Switch>
        <Route exact path={importRoute}>
          <PageBody>
            <BulkDataImportUploadFileV2
              category={category}
              apiEndpoint={apiEndpoint}
              name={name}
              nextRoute={sessionRoute}
              templateParams={templateParams}
            />
          </PageBody>
        </Route>
        <Route exact path={sessionRoute}>
          <UploadSessionV2
            apiEndpoint={apiEndpoint}
            previousRoute={importRoute}
            tableName={tableName}
            row={row}
            entity={entity}
            tableActions={tableActions}
            hiddenColumns={hiddenColumns}
            onAfterConfirmRoute={onAfterConfirmRoute}
            ignoreQueryParams={ignoreQueryParams}
            getCustomEditCellAction={getCustomEditCellAction}
          />
        </Route>
        <Route exact path={anyRoute}>
          <InternalRedirect to={importRoute} />
        </Route>
      </Switch>
    </PageWrapper>
  )
}
